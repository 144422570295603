<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ id ? (readonly ? "Просмотр" : "Редактирование") : "Новая запись" }}
      </template>  
      <v-col cols="12" sm="12" class="pt-0">
        <s-document-btn-list :name="'Список работ'" :source="listSource" :parent_data="parent_data" @select="onSelectFromList($event)" />
      </v-col>
      <a-form-model v-model="data" :model="model" :config="{ readonly, dense: true }" :errors="errors" @validate="validate($event)" @commit="onFormCommit($event)" />
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="!readonly" @click="submit()"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getAccess, getForm, submitForm } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess, getForm, submitForm],
  props: {
    dataTable: Array,
    id: Number,
    value: Boolean,
    RO: Array,
    parent_data: Object,
    type: { type: Number, default: 0 },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.documentActWorkClient,
      api: "",
      rowEdit: {},
      rows: [],
    };
  },
  computed: {
    model() {
      let model = JSON.parse(JSON.stringify(this.m.formDetail));
      model.forEach((el) => {
        if (this.RO.includes(el.name) && this.data[el.name] !== null) {
          el.readonly = true;
        }
      });
      return model;
    },
    listSource() {
      let res = {
        api: "/mechti/construction-schedule",
        title: "Список работ по графику",
        model: "works",
        fields: "department,name,work_cost,status,createdby_id",
        filter: { object_id: this.parent_data?.object_id || 0 },
      };
      return res;
    },
  },
  created() {
    this.updateData(this.id);
  },
  watch: {
    id(v) {
      if (v) this.row = this.dataTable.find((el) => el.id == v);
    },
    value() {
      if (this.value) {
        if (this.id) this.row = this.dataTable.find((el) => el.id == this.id);
      }
    },
    "data.value"(v) {
      this.calcPrice(0);
    },
    "data.price"(v) {
      if (!v) return;
      if (!this.loading || 1 == 11) this.calcPrice(1);
    },
    "data.amount"(v) {
      if (!v) return;
      if (!this.loading || 1 == 11) this.calcPrice(1);
    },
  },
  methods: {
    async onFormCommit(e) {},
    afterFetchData() {
      this.calcPrice(0);
      this.loading = false;
    },
    resetForm() {},
    calcPrice(v) {
      let amount = this.data.amount;
      if (v == 1) {
        if (this.data.price && amount) this.data.value = this.data.price * amount;
      } else if (this.data.value && amount) this.data.price = this.data.value / amount;
    },
    async fetchData() {
      this.loading = true;
      this.rows = JSON.parse(JSON.stringify(this.dataTable));
      if (this.id) {
        this.rowEdit = this.rows.find((el) => el.id == this.id);
        for (const name in this.rowEdit) {
          this.$set(this.data, name, this.rowEdit[name]);
        }
      }
      this.afterFetchData();
    },
    beforeSave() {
      //if (this.data.status === null) this.data.status = 0;
    },
    post() {
      let rows = [];
      this.rows.forEach((r) => {
        if (r.id == this.id) {
          rows.push(this.data);
        } else {
          rows.push(r);
        }
      });
      if (!this.id) {
        rows.push(this.data);
      }
      this.$emit("save", rows);

      this.$emit("input");
    },
    onSelectFromList(e) {
      this.data.name = e.name;
      this.data.amount = 1;
      this.data.measure = "шт";
      this.data.price = e.work_cost;
      this.data.value = e.work_cost;
    },
  },
};
</script>
